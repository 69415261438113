import logo from './logo.svg';
import './App.css';
import {KindeProvider, useKindeAuth} from "@kinde-oss/kinde-auth-react";

function App() {

  const { login, register, logout } = useKindeAuth();

  async function signUp() {
    await register();
  }

  async function signIn() {
    await login();
  }

  const {user} = useKindeAuth();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         Pos Test {user?.given_name}
        </p>
        <button onClick={() => signUp()} type="button">Sign up</button>
        <button onClick={() => signIn()} type="button">Sign In</button>
        <button onClick={logout} type="button">Sign out</button>
      </header>
    </div>
  );
}

export default App;
